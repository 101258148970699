import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
const Almawared = React.lazy(() => import("pages/Almawared"));
const ThankPages = React.lazy(() => import("pages/Almawared/thankPage"));

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route path="/" element={<Almawared />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/thank" element={<ThankPages />} />
            <Route path="/almawared" element={<Almawared />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
